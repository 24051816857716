import Banner from "../components/Banner"
import { Text, AspectRatio, Grid, Box } from "theme-ui"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import Layout from "../components/Layout"
import ContentSection from "../components/ContentSection"
import SEO from "../components/seo"
import { Slide } from "react-awesome-reveal"

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`

export const workshopImage = graphql`
  fragment workshopImage on File {
    childImageSharp {
      fluid(maxWidth: 400) {
        ...GatsbyImageSharpFluid_withWebp
      }
    }
  }
`

export const query = graphql`
  query {
    mobileBanner: file(relativePath: { eq: "workshopMobileBanner.png" }) {
      ...fluidImage
    }
    desktopBanner: file(relativePath: { eq: "workshopDesktopBanner.png" }) {
      ...fluidImage
    }
    workshop1: file(relativePath: { eq: "workshop1.png" }) {
      ...workshopImage
    }
    workshop2: file(relativePath: { eq: "workshop2.png" }) {
      ...workshopImage
    }
    workshop3: file(relativePath: { eq: "workshop3.png" }) {
      ...workshopImage
    }
  }
`

export default function Workshop({ data }) {
  return (
    <Layout>
      <SEO title="Workshops" />
      <Banner
        desktopImage={data.desktopBanner.childImageSharp.fluid}
        mobileImage={data.mobileBanner.childImageSharp.fluid}
        heading="Workshop &amp; Events"
        body={
          <Text variant="secondary">
            For those of us who love gardening and simply don’t have the extra
            space at home, terrariums are a perfect way to get started. Get
            access to our exclusive workshops that imparts the ancient skill and
            knowledge on how to build beautiful gardens inside cozy glass
            containers.
          </Text>
        }
        alt="workshop"
      />
      <ContentSection
        heading="Discover the Magic of a Green Makeover Through What We Offer"
        body={
          <Grid columns={[1, 3]} my={4} gap={5}>
            <Box>
              <Img
                fluid={data.workshop1.childImageSharp.fluid}
                alt="workshop1"
              />
            </Box>
            <Box>
              <Img
                fluid={data.workshop2.childImageSharp.fluid}
                alt="workshop2"
              />
            </Box>
            <Box>
              <AspectRatio ratio={1 / 0.7}>
                <Img
                  fluid={data.workshop3.childImageSharp.fluid}
                  alt="workshop3"
                />
              </AspectRatio>
            </Box>
          </Grid>
        }
      />
      <Slide triggerOnce={true} direction="right" duration={500}>
        <Box m={[3, 5]}>
          <Text sx={{ fontSize: [2, 4] }} color="accent">
            Upcoming workshop
          </Text>
          <Text sx={{ fontSize: [2, 4] }} color="accent">
            To be announced soon. Watch this space
          </Text>
        </Box>
      </Slide>
      <Box
        bg="highlight5"
        m={[3, 5]}
        py={4}
        px={[4, 5]}
        sx={{ textAlign: "center" }}
      >
        <Slide triggerOnce={true} direction="down" duration={500}>
          <Text variant="default" color="accent2" sx={{ fontSize: [1, 3] }}>
            Gather a group of friends for an exclusive private session or join
            in with one of our publicly hosted workshops, It’s a time of great
            fun and learning and most importantly, greening up the earth
            together, one terrarium at a time. For corporate workshop please get
            in touch with us for multiple option to suit your need.
          </Text>
        </Slide>
      </Box>
    </Layout>
  )
}
